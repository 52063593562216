import './modernizr';
import svg4everybody from 'svg4everybody';
// import 'indexeddb-getall-shim';
// Load pollyfills
(function() {
  svg4everybody(); // Pollyfill for SVG fragments
  // Loads Fetch pollyfill - Add if required
  // const loadFetchPollyFill = () => import(/* webpackChunkName: "whatwg-fetch" */ 'whatwg-fetch');

  // if (!('fetch' in window)) {
  //   loadFetchPollyFill();
  // }
  // Polly fill for picture and srcset
  const loadPictureFill = () => import(/* webpackChunkName: "picturefill" */ 'picturefill');

  if (!'HTMLPictureElement' in window) {
    loadPictureFill();
  }

  // Needed for Lozad - Lazy loading module
  const loadObserverPollyFill = () => import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer');

  if (!('IntersectionObserver' in window)) {
    loadObserverPollyFill();
  }
})();
