import Pikaday from 'pikaday';
import { format } from 'date-fns';
import 'pikaday/css/pikaday';

const AddPicker = picker => {
  new Pikaday({
    field: picker,
    firstDay: 1,
    format: 'dd-MM-yyyy',
    toString: (date, f) => format(date, f),
  });
};

global.AddPicker = AddPicker;

export default () => {
  const pickers = document.querySelectorAll('.date-pick');

  [...pickers].forEach(AddPicker);
};
