import FontFaceObserver from 'fontfaceobserver';

const SourceSans = new FontFaceObserver('Source Sans Pro');
SourceSans.load()
  .then(() => {
    document.body.classList.add('Source-Sans-Pro-loaded');
  })
  .catch(err => {
    document.body.classList.add('Source-Sans-Pro-failed');
  });
