export default () => {
    const btns = document.querySelector('.rating-buttons');
    const video = document.querySelector('video');
    const asVideo = document.getElementById('as-video')

    if(video) {
        video.addEventListener('ended', () => {
            btns.style.display = 'block';
        });
        // video.addEventListener('play', () => {
        //     btns.style.display = 'none';
        // })
    }
    if(asVideo) {
      asVideo.addEventListener('play', () => {
        Rails.ajax({
          type: "POST",
          url: "/user_activity_sessions",
          data: "user_id=" + asVideo.dataset.user +"&activity_session_id=" + asVideo.dataset.activitySession,
        })
      });

      asVideo.addEventListener('ended', () => {
        Rails.ajax({
          type: "GET",
          url: "/user_activity_sessions/uas_update?user_id=" + asVideo.dataset.user +"&activity_session_id=" + asVideo.dataset.activitySession,
        })
      });
    }
};
