import CookieMgmt from '@djforth/cookie_mgmt_fp';

import { AddClick, AddSelect, GetButtons, PersistData, ComposeClickHandler } from './accessibility_helpers';

// Apply FontSize to page
const ApplyFontSize = target => {
  const fontSize = target.getAttribute('data-size');
  document.documentElement.style.fontSize = fontSize;
};

// Checks cookie and applies to linkTag if it exists
const checkCookie = cookieName => {
  const fontSizeCookie = CookieMgmt(cookieName);
  // Applies css if available
  const fontSize = fontSizeCookie.getValue();
  if (fontSize) {
    document.documentElement.style.fontSize = fontSize;
  }

  return fontSizeCookie;
};

const FontSizing = (cookieName = 'font-size') => {
  const fontSizeButtons = GetButtons('[data-font-size]');

  const fontSizeCookie = checkCookie(cookieName);
  const clickHandler = ComposeClickHandler([
    AddSelect(fontSizeButtons, 'text-increases'),
    ApplyFontSize,
    PersistData(fontSizeCookie, 'data-size'),
  ]);

  fontSizeButtons.forEach(btn => {
    AddClick(btn, clickHandler);
  });
};

export default FontSizing;