import '@babel/polyfill';
import './pollyfills';
import './load_service_worker';
import './font_observer';
import datepicker from './datepicker';
import { start as startUjs } from '@rails/ujs';
import Rails from '@rails/ujs';
import lozad from 'lozad';

import fontSizing from './accessibility/font_sizing';
import activityVideo from './activity_video';
import '../controllers/index'


// import ablty from './ablty_word';
// import { start as activestorage } from '@rails/activestorage'; // Only add if required

// See - https://github.com/morsedigital/morse-utils for details
import {
  EventListener,
  // AlertClose, // Adds alert close functionality
  // AlertReset, // Adds reset - opens all alerts
  // Modal, // Modal
  Cookiebar,
} from '@morsedigital/morse-utils';

(() => {
  startUjs(); // Start UJS
  global.Rails = Rails;
  // activestorage(); // Start Activestorage
})();

(() => {
  // Utility functions
  const modules = [
    // AlertClose(),
    // AlertReset(),
    Cookiebar('cookie-prompt'), // Should be id of Cookie prompt DOM Node
    // Modal(),
  ];

  EventListener(modules);
})();

// Lazy load images
(() => {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
})();

(() => {
  datepicker();
})();

(() => {
  // Accessibility
  fontSizing();
})();

(() => {
  activityVideo();
})();

// (() => {
//   ablty();
// })()
